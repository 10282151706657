import React  from 'react';
//import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
//import {Typography} from '@material-ui/core'; 
import NavBar from './components/NavBar'
//import Grid from './components/Grid'
import Footer from './components/Footer'
import Header from './components/Header'
//import myBackgroundImage from './components/headerpic.jpg'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//changes to imports 
//mport SecurityIcon from '@material-ui/icons/Security';
//import EventNoteIcon from '@material-ui/icons/EventNote';
//import TrendingUpIcon from '@material-ui/icons/TrendingUp';
//import ImportExportIcon from '@material-ui/icons/ImportExport';
//import ComputerIcon from '@material-ui/icons/Computer';
//import HttpIcon from '@material-ui/icons/Http';
   /*  <img src={process.env.PUBLIC_URL+"lmhslogo.jpeg"}  alt="LMHS Logo" /> */


function App() {

  return (
    <div className="App" >
        <Header/>
	<NavBar/>
        <Footer/>
    </div>
  );
}

export default App;
