import React from 'react';
import './Header.css';
//import myBackgroundImage from './headerpic.jpg'; 

/*	    <img className="mybackgroundImg" src={process.env.PUBLIC_URL+"headerpic.jpg"} alt ="background Image" /> */
function Header() {
    return (
	<div className="Header" >
	<div className="LogoHeader" >
	    <img src={process.env.PUBLIC_URL+"LMHSLogo4.png"}  alt="LMHS Logo" />
	 </div>
	 <div className="HeaderText">
	<div className="BottomHeader">
	  <h2> <strong> LANSDOWNE</strong> <br></br> MENTAL HEALTH SERVICES </h2> 
	</div>
	</div>
	</div >
)
}

export default Header
