import React from 'react';
import './Footer.css';
/*import {BottomNavigation} from '@material-ui/core';
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
*/
function Footer() {
    return (
	<div className="Footer" >
	<h2>  Phone : <a href="tel:5715100016"> (571) 510-0016 </a> <br /> Fax:    (866) 422-2128 <br></br> Email :  <a href="mailto:admin@lansdownemhs.com">admin@lansdownemhs.com</a> <br></br> Address : <a href="https://goo.gl/maps/aVsv6f2xjM1BQ2qC7" target="_blank" rel="noopener noreferrer">44121 Harry Bryd Hwy, STE 275, Ashburn, VA 20147</a>
 </h2> 
	</div >
)
}

export default Footer
