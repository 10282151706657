import React, { Component, lazy, Suspense  }  from 'react';
import Container from 'react-bootstrap/Container';
import {
    BrowserRouter as Router, Switch,
    Route,
    Link
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NavBar.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
//import { LinkContainer } from 'react-router-bootstrap';
const Services  = lazy(() => import('./Services'));
const Home  = lazy(() => import( './Home'));
const Contact  = lazy(() => import( './Contact'));
const Faq  = lazy(() => import('./Faq'));
const Sangeeta  = lazy(() => import('./Sangeeta'));
const Igor  = lazy(() => import('./Igor'));

const Swathi = lazy(() => import( './Swathi'));
const LaurieThomson = lazy(() => import( './LaurieThomson'));
const Testimonials = lazy(() => import( './Testimonials'));
const VirtualAssistant = lazy(() => import( './VirtualAssistant'));
const Appointments = lazy(() => import( './Appointments'));
const PatientForm = lazy(() => import( './Forms'));
const Resources = lazy(() => import( './Resources'));
const Blog = lazy(() => import( './Blog'));

const ServicesPage = () => (
     <Suspense fallback={<div>Services page is Loading...</div>}>
	<Services />
     </Suspense>
);
const HomePage = () => (
     <Suspense fallback={<div>Home page is Loading...</div>}>
	<Home />
     </Suspense>
);
const ContactPage = () => (
     <Suspense fallback={<div>Contact page is Loading...</div>}>
	<Contact />
     </Suspense>
);
const FaqPage = () => (
     <Suspense fallback={<div>Faq page is Loading...</div>}>
	<Faq />
     </Suspense>
);
const SangeetaPage = () => (
     <Suspense fallback={<div>Sangeeta page is Loading...</div>}>
	<Sangeeta />
     </Suspense>
);
const IgorPage = () => (
     <Suspense fallback={<div>Igor page is Loading...</div>}>
	<Igor />
     </Suspense>
);
const SwathiPage = () => (
     <Suspense fallback={<div>Swathi page is Loading...</div>}>
	<Swathi />
     </Suspense>
);
const LaurieThomsonPage = () => (
     <Suspense fallback={<div>LaurieThomson page is Loading...</div>}>
	<LaurieThomson />
     </Suspense>
);
const TestimonialsPage = () => (
     <Suspense fallback={<div>Testimonials page is Loading...</div>}>
	<Testimonials />
     </Suspense>
);
const VirtualAssistantPage = () => (
     <Suspense fallback={<div>VirtualAssistant page is Loading...</div>}>
	<VirtualAssistant />
     </Suspense>
);
const AppointmentsPage = () => (
     <Suspense fallback={<div>Appointments page is Loading...</div>}>
	<Appointments />
     </Suspense>
);
const ResourcesPage = () => (
     <Suspense fallback={<div>Resources page is Loading...</div>}>
	<Resources />
     </Suspense>
);
const BlogPage = () => (
     <Suspense fallback={<div>Blog page is Loading...</div>}>
	<Blog />
     </Suspense>
);
//function NavBar() {
export default class NavBar extends Component {
    render() {
  return (
   <Router>
   <div className="lmhs-navbar" >
    <Suspense fallback={<div>Page is Loading...</div>}>
    <Navbar collapseOnSelect expand="sm" bg="light" variant="light">
      <Container>
        <Navbar.Brand  eventKey="1" as={Link} to='/home'> Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link eventKey="2" as={Link} to="/services">Services</Nav.Link>
            <NavDropdown title="Providers" id="collasible-nav-dropdown">
              <NavDropdown.Item  eventKey="3" as={Link} to="/sangeeta">Sangeeta Chitlu, MD</NavDropdown.Item>
	      <NavDropdown.Divider />
              <NavDropdown.Item  eventKey="11" as={Link} to="/igor">Igor Pryadkin, PMHNP-BC</NavDropdown.Item>
	      <NavDropdown.Divider />
              <NavDropdown.Item  eventKey="12" as={Link} to="/laurieThomson">Laurie Thomson, LPC </NavDropdown.Item>
	      <NavDropdown.Divider />
              <NavDropdown.Item  eventKey="13" as={Link} to="/swathi">Swathi Ala, NP-C </NavDropdown.Item>
            </NavDropdown> 
            <Nav.Link href="https://phr.charmtracker.com/login.sas?FACILITY_ID=18271991c0b587039e13e82fd8bdfdb73e588a2496a8c7b998ee2e5ce9d5cc8ac81b440bc8a53a00" target="_bank">Patient Portal</Nav.Link>
          </Nav>
          <Nav>
	    <Nav.Link  eventKey="5" as={Link} to="/contact">Contact</Nav.Link>
	    <Nav.Link  eventKey="6" as={Link} to="/faq">FAQ</Nav.Link>
            <Nav.Link  eventKey="7" as={Link} to="/testimonials">Testimonials</Nav.Link>
            <Nav.Link  eventKey="9" as={Link} to="/appointments">Appointments</Nav.Link>
            <Nav.Link  eventKey="10" as={Link} to="/resources">Resources</Nav.Link>
            <Nav.Link  eventKey="8" as={Link} to="/virtualAssistant">Virtual Assistant</Nav.Link>
            <Nav.Link  eventKey="14" as={Link} to="/blog">Blog</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </Suspense>
    </div>
    <div>
       <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route exact path="/services">
            <ServicesPage />
          </Route>
          <Route exact path="/contact">
            <ContactPage />
          </Route>
          <Route exact path="/faq">
            <FaqPage />
          </Route>
          <Route exact path="/testimonials">
            <TestimonialsPage />
          </Route>
          <Route exact path="/sangeeta">
            <SangeetaPage />
          </Route>
          <Route exact path="/swathi">
            <SwathiPage />
          </Route>
          <Route exact path="/igor">
            <IgorPage />
          </Route>
          <Route exact path="/lauriethomson">
            <LaurieThomsonPage />
          </Route>
          <Route exact path="/virtualAssistant">
            <VirtualAssistantPage />
          </Route>
          <Route exact path="/appointments">
            <AppointmentsPage />
          </Route>
          <Route exact path="/resources">
            <ResourcesPage />
          </Route>
          <Route exact path="/blog">
            <BlogPage />
          </Route>
          <Route exact path="/patientForm">
            <PatientForm />
          </Route>
        </Switch>
     </div>
   </Router>    
  );
}


}

//export default NavBar
